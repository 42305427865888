import CATEGORY_ACTION_TYPES from './category.types';

export const CATEGORY_INITIAL_STATE = {
  categoryLists: [],
};

export const CategoryReducer = (state = CATEGORY_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CATEGORY_ACTION_TYPES.LOAD_CATEGORIES: {
      return { ...state, categoryLists: payload };
    }
    default:
      return state;
  }
};
