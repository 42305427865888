import LECTURE_LOGS_ACTION_TYPES from './lectureLogs.types';

export const LECTURE_LOGS_INITIAL_STATE = {
  lectureLogsLists: [],
};

export const LectureLogsReducer = (state = LECTURE_LOGS_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case LECTURE_LOGS_ACTION_TYPES.LOAD_LECTURE_LOGS: {
      return { ...state, lectureLogsLists: payload };
    }
    default:
      return state;
  }
};
