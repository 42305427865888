import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';

const List = Loadable(lazy(() => import('app/views/student-lecture/LectureLogs')));
const View = Loadable(lazy(() => import('app/views/student-lecture/LectureLogsView')));

const studentlectureRoutes = [
  {
    path: '/student-lecture',
    element: <List />,
    auth: authRoles.admin
  },
  {
    path: '/student-lecture/:studentLectureId',
    element: <View />,
    auth: authRoles.admin
  }
];

export default studentlectureRoutes;

