import STUDENT_LECTURE_ACTION_TYPES from './studentLecture.types';

export const STUDENT_LECTURE_INITIAL_STATE = {
  studentLectureLists: [],
};

export const StudentLectureReducer = (state = STUDENT_LECTURE_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case STUDENT_LECTURE_ACTION_TYPES.STUDENT_LECTURE_LOGS: {
      return { ...state, studentLectureLists: payload };
    }
    default:
      return state;
  }
};
