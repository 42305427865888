import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger'
import reducer from './reducer';


const initialState = {};

const logger = createLogger({
  // ...options
});

const middlewares = [logger, thunk];
let devtools = (x) => x;

if (
  window.__REDUX_DEVTOOLS_EXTENSION__
) {
  devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
}

export const Store = createStore(
  reducer,
  initialState,
  compose(applyMiddleware(...middlewares), devtools)
);
