import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';

const List = Loadable(lazy(() => import('app/views/lecture-logs/LectureLogs')));

const lectureLogRoutes = [
  {
    path: '/lecture-logs',
    element: <List />,
    auth: authRoles.admin
  }
];

export default lectureLogRoutes;

