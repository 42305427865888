import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';

const AdminList = Loadable(lazy(() => import('app/views/admins/Admin')));
const AdminCreate = Loadable(lazy(() => import('app/views/admins/AdminCreate')));
const AdminUpdate = Loadable(lazy(() => import('app/views/admins/AdminUpdate')));

const adminRoutes = [
  {
    path: '/admins',
    element: <AdminList />,
    auth: authRoles.admin
  },
  {
    path: '/admins/create',
    element: <AdminCreate />,
    auth: authRoles.admin
  },
  {
    path: '/admins/edit/:adminId',
    element: <AdminUpdate />,
    auth: authRoles.admin
  }
];

export default adminRoutes;

