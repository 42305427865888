import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import { MatxTheme } from "./components";
import { AuthProvider } from "./contexts/JWTAuthContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import { Store } from "./store/Store";
import routes from "./routes";
import { AlertProvider } from "./contexts/AlertContext";
import { SnackbarProvider } from "./contexts/SnackbarContext";

const App = () => {
  const content = useRoutes(routes);

  return (
    <Provider store={Store}>
      <SettingsProvider>
        <MatxTheme>
          <AuthProvider>
            <AlertProvider>
              <SnackbarProvider>{content}</SnackbarProvider>
            </AlertProvider>
          </AuthProvider>
        </MatxTheme>
      </SettingsProvider>
    </Provider>
  );
};

export default App;
